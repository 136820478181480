import {CruiseDifficulty} from "../../model/modelEnums/CruiseDifficulty";
import {Cruise} from "../../model/Cruise";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";

export default class CruiseRequest {
  id: string = "";
  price: number = 0;
  cruiseDifficulty: CruiseDifficulty = CruiseDifficulty.TRIVIAL;
  startLocation: string = "";
  finishLocation: string = "";
  startDay: string = "";
  finishDay: string = "";
  titlePl: string = "";
  titleEn: string = "";
  descriptionPl: string = "";
  descriptionEn: string = "";
  planPl: string = "";
  planEn: string = "";
  youtubeLink: string = "";


  public static fromEmpty() {
    return new CruiseRequest();
  }

  public static fromExisting(cruise: Cruise) {
    const cruiseFormData = new CruiseRequest();
    cruiseFormData.id = cruise.id;
    cruiseFormData.price = cruise.pricePln;
    cruiseFormData.titlePl = cruise.titlePl;
    cruiseFormData.titleEn = cruise.titlePl;
    cruiseFormData.cruiseDifficulty = cruise.cruiseDifficulty;
    cruiseFormData.startLocation = cruise.startLocation;
    cruiseFormData.finishLocation = cruise.finishLocation;
    cruiseFormData.startDay = cruise.startDay;
    cruiseFormData.finishDay = cruise.finishDay;
    cruiseFormData.descriptionPl = cruise.descriptionPl;
    cruiseFormData.descriptionEn = cruise.descriptionEn;
    cruiseFormData.planPl = cruise.planPl;
    cruiseFormData.planEn = cruise.planEn;
    cruiseFormData.youtubeLink = cruise.youtubeLink ?? "";
    return cruiseFormData;
  }

  setPrice = (input: number) => {
    this.price = input;
  }

  setTitlePl = (input: string) => {
    this.titlePl = input;
  }

  setTitleEn = (input: string) => {
    this.titleEn = input;
  }

  setCruiseDifficulty = (input: { props: SimpleOptionProps }) => {
    this.cruiseDifficulty = input.props.value as CruiseDifficulty;
  }

  setStartLocation = (input: string) => {
    this.startLocation = input;
  }

  setFinishLocation = (input: string) => {
    this.finishLocation = input;
  }

  setStartDay = (input: string) => {
    this.startDay = input;
  }

  setFinishDay = (input: string) => {
    this.finishDay = input;
  }

  setDescriptionPl = (input: string) => {
    this.descriptionPl = input;
  }

  setDescriptionEn = (input: string) => {
    this.descriptionEn = input;
  }

  setPlanPl = (input: string) => {
    this.planPl = input;
  }

  setPlanEn = (input: string) => {
    this.planEn = input;
  }

  setYoutubeLink = (input: string) => {
    this.youtubeLink = input;
  }
}